import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Vue from 'vue';

// register globally
Vue.component('multiselect', {
  mixins: [Multiselect],
  $slots: {
    noResult: 'asdsad',
    noOptions: 'A lista üres',
  },
  props: {
    selectLabel: {
      default: '',
    },
    selectedLabel: {
      default: '',
    },
    selectGroupLabel: {
      default: '',
    },
    noOptions: {
      default: '',
    },
    placeholder: {
      default: 'Válasszon...',
    },
    tagPlaceholder: {
      default: 'Válasszon...',
    },
    deselectLabel: {
      default: '',
    },
    deselectGroupLabel: {
      default: '',
    },
    trackBy: {
      default: 'id',
    },
    label: {
      default: 'text',
    },
  },
});
