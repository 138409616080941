import Vue from 'vue';
import Vuex from 'vuex';
import settings from '../settings';
import { app } from './modules/app';
import modal from './modules/modal';

Vue.use(Vuex);

const debug = !settings.isProd;

const store = new Vuex.Store({
  modules: { app, modal },
  strict: debug,
  plugins: [],
});
export default store;
