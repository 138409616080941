import Vue from 'vue';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';

const requireComponents = require.context('.', true, /\.vue$/, 'lazy');

requireComponents.keys().forEach(fileName => {
  var componentName =
    'k-' +
    kebabCase(
      fileName
        .split('/')
        .pop()
        .split('.')[0]
    );

  if (componentName) {
    Vue.component(componentName, () => import(`${fileName}`));
  } else {
    console.error('Nincs komponens név: ', fileName);
  }
});
