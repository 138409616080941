import $ from 'jquery';

import { getToken } from './token';
import HttpStatusCode from '../enums/httpStatusCode';
import settings from '../settings';

class HttpFunctions {
  constructor() {}
  requestCache = [];

  /**
   *
   * @param {Object} payload
   * @param {String} payload.url
   * @param {Object} payload.data
   * @param {Object} payload.options
   * @param {Boolean} payload.options.fileUpload
   * @param {String}  payload.options.dataType
   */
  post({ url, data, options }) {
    let disableAbort = options && options.disableAbort;

    // Cancel previous call
    let previousRequest = this.requestCache.find(f => f.url == url);
    if (previousRequest && !disableAbort) {
      if (previousRequest.promise.abort) {
        previousRequest.promise.abort();
      }
    }
    let fileUpload = options && options.fileUpload;
    let dataType = options && options.dataType;

    let promise = $.ajax({
      url: url,
      type: 'POST',
      dataType: dataType || 'json',
      global: false,
      processData: !fileUpload,
      contentType: fileUpload
        ? false
        : 'application/x-www-form-urlencoded; charset=UTF-8',
      data: data,
      xhrFields: {
        withCredentials: true,
      },
      beforeSend: function(xhr) {
        if (url.indexOf(settings.baseUrl) != -1) {
          var authToken = getToken();
          if (authToken)
            xhr.setRequestHeader('Authorization', 'Bearer ' + authToken);
        }
      },
    })
      .fail((jqXHR, textStatus, errorThrown) => {
        if (jqXHR.status == HttpStatusCode.FORBIDDEN) {
          // router.push('/nopermission/');
        }
        if (jqXHR.status == HttpStatusCode.UNAUTHORIZED) {
        }
      })
      .always(jqXHR => {
        const index = this.requestCache.findIndex(
          f => f.url != url && f.promise.readyState === XMLHttpRequest.DONE
        );

        if (index > -1) {
          this.requestCache.splice(index, 1);
        }

        try {
          if (!settings.isProd) {
            console.log(
              `%c[HTTP]%c ${url.replace(settings.baseUrl, '')}, params: `,
              'color: blue;',
              'color: unset;',
              { requestData: data || null, response: jqXHR }
            );
          }
        } catch (error) {
          console.log('Nem sikerült megjeleníteni a kapcsolatot');
        }
      });
    if (!previousRequest) {
      this.requestCache.push({ url, promise });
    } else {
      previousRequest.promise = promise;
    }
    return promise;
  }
}
let instance = new HttpFunctions();
export { instance as HttpContext };
