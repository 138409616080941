import {
  makeVuexList,
  mergeModules,
  makeVuexProp,
} from '../../utils/vuexUtils';

let moduleName = 'app';

let { module: szervezetek, storeTypes: szervezetekStoreTypes } = makeVuexList({
  namespace: moduleName,
  listName: 'szervezetek',
  key: 'Id',
});

let { module: hirKategoriak, storeTypes: hirKategoriakStoreTypes } =
  makeVuexList({
    namespace: moduleName,
    listName: 'hirKategoriak',
    key: 'Id',
  });
let { module: hirStatuszok, storeTypes: hirStatuszokStoreTypes } = makeVuexList(
  {
    namespace: moduleName,
    listName: 'hirStatuszok',
    key: 'Id',
  }
);

let { module: user, storeTypes: userStoreTypes } = makeVuexProp({
  namespace: moduleName,
  propName: 'user',
  value: null,
});
let { module: jogosultProjektek, storeTypes: jogosultProjektekStoreTypes } =
  makeVuexList({
    namespace: moduleName,
    listName: 'jogosultProjektek',
    key: 'Id',
  });
let { module: noemiLink, storeTypes: noemiLinkStoreTypes } = makeVuexProp({
  namespace: moduleName,
  propName: 'noemiLink',
  value: null,
});
let AppStoreTypes = {
  szervezetek: szervezetekStoreTypes,
  hirKategoriak: hirKategoriakStoreTypes,
  hirStatuszok: hirStatuszokStoreTypes,
  user: userStoreTypes,
  jogosultProjektek: jogosultProjektekStoreTypes,
  noemiLink: noemiLinkStoreTypes,
};
let app = mergeModules([
  szervezetek,
  hirKategoriak,
  hirStatuszok,
  user,
  jogosultProjektek,
  noemiLink,
]);

export { app, AppStoreTypes };
