<template>
  <vc-date-picker
    :value="localValue"
    v-bind="{ ...$attrs }"
    @input="OnInput"
    is24hr
    locale="hu"
  >
    <template v-slot="{ inputValue, updateValue, togglePopover }">
      <b-form-input
        class="custom-date-picker"
        :value="inputValue"
        autocomplete="off"
        :required="required"
        @input="
          updateValue($event, {
            formatInput: false,
            hidePopover: false,
            debounce: 550,
          })
        "
        @change="
          updateValue($event, {
            formatInput: true,
            hidePopover: false,
          })
        "
        @click="
          togglePopover({
            placement: 'bottom-start',
          })
        "
      ></b-form-input>
    </template>
  </vc-date-picker>
</template>

<script>
import format from 'date-fns/format';
import { mapGetters } from 'vuex';

export default {
  name: 'html-date-picker',
  data() {
    return {
      localValue: '',
    };
  },
  mounted() {},
  created() {},
  methods: {
    OnInput(e) {
      if (e) {
        this.$emit('input', new Date(e).toISOString());
      } else {
        this.$emit('input', '');
      }
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {
    value: {
      handler(v) {
        if (v) {
          // eslint-disable-next-line
          let isoStr = format(new Date(v), "yyyy-MM-dd'T'HH:mm");
          this.localValue = isoStr;
        } else {
          this.localValue = '';
        }
      },
      immediate: true,
    },
  },
  props: {
    value: {},
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
