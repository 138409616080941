<template>
  <div class="full-page login">
    <b-container>
      <b-row class="justify-content-center">
        <b-col cols="4" class="d-flex justify-content-center">
          <b-form @submit.prevent="OnSubmit">
            <h2>Stream<span class="text-blue">Next.</span></h2>
            <h3>Konasoft Tartalom Management</h3>
            <b-form-group>
              <b-form-input
                v-model="form.Email"
                type="email"
                placeholder="Email"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input
                v-model="form.Password"
                type="password"
                placeholder="Jelszó"
                required
              ></b-form-input>
            </b-form-group>
            <k-button
              block
              type="submit"
              variant="primary"
              icon="fa-sign-in-alt"
              label="Belépés"
              :loading="formLoading"
            ></k-button>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import HtmlDatePicker from '../components/controls/HtmlDatePicker.vue';
import { NotificationFunctions } from '../functions/NotificationFunctions';
import { prevRoute } from '../main';
import { userService } from '../services/UserService';
import { setToken } from '../utils/token';
export default {
  name: 'login-page',
  data() {
    return {
      formLoading: false,
      form: {
        Email: '',
        Password: '',
      },
    };
  },
  mounted() {},
  created() {},
  methods: {
    OnSubmit() {
      this.Login();
    },
    async Login() {
      this.formLoading = true;
      try {
        await userService.Login({ form: this.form });
        if (prevRoute) {
          let path = prevRoute.slice(1, prevRoute.length);
          let to = this.$router.resolve(path);
          if (to.route.name == 'Login') {
            this.$router.push({ name: 'Articles' });
          } else {
            this.$router.push(to.location.path);
          }
        } else {
          this.$router.push({ name: 'Articles' });
        }
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.formLoading = false;
    },
  },
  computed: {},
  watch: {},
};
</script>
