import Vue from 'vue';
import Vuelidate from 'vuelidate';
import vuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';

Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  template: templates.singleErrorExtractor.bootstrap4,
  messages: {
    required: 'Kötelező mező',
    sameAs: 'Nem egyezik a két jelszó',
    email: 'Az email formátuma nem megfelelő',
    telefonszam: 'Hibás formátum, +3630 123-4567',
    numeric: 'Csak szám adható meg',
    minLength: 'A mezőnek legalább {min} karakternek kell lennie',
    minLengthJelszo: 'A jelszónak legalább 8 karakter hosszúnak kell lennie',
    minValue: 'Minimum érték: {min}',
    maxValue: 'Maximum érték: {max}',
    minDate: 'Érvénytelen dátum',
    maxDate: 'Érvénytelen dátum',
    minLength: 'Minimum {min} karaktert adjon meg',
    maxLength: 'Maximum {max} karaktert adjon meg',
    minTanu: 'Legalább {min} tanú kíválasztása kötelező',
    maxTanu: 'Maximum {max} tanút lehet kiválasztani',
    minFogvatartott: 'Legalább {min} fogvatartott kiválasztása kötelező',
    maxFogvatartott: 'Maximum {max} fogvatartottat lehet kiválasztani',
    azonosito: 'Hibás azonosító',
    egyezoJelszo: 'Nem egyezik a két jelszó',
  },
});
