'use strict';

import settings from '../settings';
import store from '../store';
import { AppStoreTypes } from '../store/modules/app';
import { HttpContext } from '../utils/httpContext';

class AppService {
  async GetAppData() {
    const url = settings.baseUrl + 'Api/App/GetAppData';
    const result = await HttpContext.post({ url });
    store.dispatch(AppStoreTypes.jogosultProjektek.set, {
      value: result.JogosultProjektek,
    });
    store.dispatch(AppStoreTypes.user.set, { value: result.Felhasznalo });
    let noemiLink =
      result.PersonalHelpdeskUrl +
      encodeURIComponent(result.PersonalHelpdeskRSA);
    store.dispatch(AppStoreTypes.noemiLink.set, { value: noemiLink });
    return result;
  }
  async GetSzervezetek() {
    const url = settings.baseUrl + 'Api/App/GetSzervezetek';
    const result = await HttpContext.post({ url });
    store.dispatch(AppStoreTypes.szervezetek.set, { value: result });
    return result;
  }

  async GetSzervezetekByKeresoSzoveg({ keresoszoveg, admin = false }) {
    let data = {
      keresoszoveg,
      admin,
    };

    const url = settings.baseUrl + 'Api/App/GetSzervezetekByKeresoSzoveg';
    const result = await HttpContext.post({ url, data });
    //store.dispatch(AppStoreTypes.szervezetek.set, { value: result });
    return result;
  }

  async GetCimkekByFelhoId({ felhoId, projektId = -1 }) {
    let data = {
      felhoId,
      projektId,
    };

    const url = settings.baseUrl + 'Api/App/GetCimkekByFelhoId';
    const result = await HttpContext.post({ url, data });
    //store.dispatch(AppStoreTypes.szervezetek.set, { value: result });
    return result;
  }
}

let instance = new AppService();
export { instance as appService };
