import $ from 'jquery';

export const PLACEHOLDER_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPcGg0AAcsBEuRzwtUAAAAASUVORK5CYII=';
export const PLACEHOLDER_IMAGE_ORANGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAMSURBVBhXY/h/eSsABVwCiOQVxhwAAAAASUVORK5CYII=';

export function formatNumber(
  numStr,
  separator = ' ',
  nullValue = '',
  floatSpearatorParse = '.',
  floatSpearatorDisplayed = ','
) {
  if (numStr == null || numStr == undefined || numStr === '') {
    return nullValue;
  }
  let max2Decimal =
    Math.round((parseFloat(numStr) + Number.EPSILON) * 100) / 100;
  var parts = (max2Decimal + '').toString().split(floatSpearatorParse);
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);

  return parts.join(floatSpearatorDisplayed);
}
export function timeout(ms) {
  return new Promise(res => setTimeout(res, ms));
}
export async function GetBase64UrlFromFile(file) {
  if (!file) {
    return null;
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = ev => {
      resolve(ev.target.result);
    };
    reader.onerror = e => {
      console.log(e);
      reject(e);
    };
    reader.onabort = e => {
      console.log(e);
      reject(e);
    };
  });
}

export function hidrateForm(vm, form, entity) {
  if (!form || !entity) {
    return;
  }
  for (const key in form) {
    if (form.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
      vm.$set(form, key, entity[key]);
    }
  }
}

export function addTooltip(element, title, options = {}) {
  $(element).tooltip({
    container: $('body'),
    boundary: $('body'),
    delay: {
      show: 100,
      hide: 100,
    },

    ...options,
  });
}

export const getScrollParent = node => {
  const regex = /(auto|scroll)/;
  const parents = (_node, ps) => {
    if (_node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node, prop) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = _node =>
    style(_node, 'overflow') +
    style(_node, 'overflow-y') +
    style(_node, 'overflow-x');
  const scroll = _node => regex.test(overflow(_node));

  const scrollParent = _node => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
};
export const getAllScrollParent = function(node) {
  let parents = [];
  let scrollParent = getScrollParent(node);
  if (scrollParent && scrollParent.tagName.toLowerCase() != 'html') {
    let otherParents = getAllScrollParent(scrollParent);
    parents.push(scrollParent, ...otherParents);
  }
  return parents;
};
export const formatHtmlBeforeDtExport = function(htmlStr) {
  if (!htmlStr) {
    return htmlStr;
  }
  return (htmlStr + '').replace(/&shy;/gi, '').replace(/<br\/>/gi, ', ');
};
export function saveToClipboard(str) {
  const input = document.createElement('input');
  input.value = str;
  input.setAttribute('readonly', '');
  input.classList.add('opacity-0');
  input.style.display = 'block';
  document.body.append(input);
  input.select();
  document.execCommand('copy');
  input.style.display = 'none';
  input.remove();
}
export function safeGetProp(object, path) {
  const pathArray = path.split('.');
  return pathArray.reduce(function(xs, x) {
    return xs && xs[x] ? xs[x] : null;
  }, object);
}
