import Vue from 'vue';
import CKEditor from '@ckeditor/ckeditor5-vue2';

window.CKEDITOR_TRANSLATIONS = window.CKEDITOR_TRANSLATIONS || {};

window.CKEDITOR_TRANSLATIONS['hu'] = window.CKEDITOR_TRANSLATIONS['hu'] || {};
window.CKEDITOR_TRANSLATIONS['hu'].dictionary =
  window.CKEDITOR_TRANSLATIONS['hu'].dictionary || {};

Object.assign(window.CKEDITOR_TRANSLATIONS['hu'].dictionary, {
  Placeholder: 'Sablonszöveg',
  'Left aligned video': 'Balra igazítás',
  'Centered video': 'Középre igazítás',
  'Right aligned video': 'Jobbra igazítás',
  'Upload Video': 'Videó beszúrása',
});

Vue.use(CKEditor);
