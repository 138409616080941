import toastr from 'toastr';

import WarningLevel from '../enums/waningLevel';
import { hirportalAdmin } from '../main';

/**
 * @type {ToastrOptions}
 */
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-right',
  preventDuplicates: false,
  onclick: null,
  toastClass: 'toast-just-text',
  showDuration: 0,
  hideDuration: 0,
  timeOut: 6000,
  extendedTimeOut: 1000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
};

let marker = '<div class="left-marker"></div>';

class NotificationFunctions {
  InfoAlert(title, text) {
    toastr.info(marker + text, title);
  }

  SuccessAlert(title, text) {
    toastr.success(marker + text, title);
  }

  WarningAlert(title, text) {
    toastr.warning(marker + text, title, {});
  }

  ErrorAlert(title, text) {
    toastr.error(marker + text, title);
  }
  AjaxError({ title = 'Hiba', text = 'Hiba történt az alkalmazásban', error }) {
    console.error(error);
    let warningLevel = WarningLevel.Error;

    if (error.statusText == 'abort') {
      return true;
    }
    if (error) {
      let responseJSON = error.responseJSON;
      if (error.jqXHR && error.jqXHR.responseJSON) {
        responseJSON = error.jqXHR.responseJSON;
      }

      if (
        responseJSON &&
        (responseJSON.ServerWarning || responseJSON.ServerError)
      ) {
        title = responseJSON.Title || title;
        text = responseJSON.Message || text;
        warningLevel = responseJSON.ErrorLevel;
      }
      if (responseJSON && responseJSON.ValidationError) {
        let hibak = responseJSON.Errors.map(m => m.Text).join(', ');
        title = 'Hibás adatok';
        text = hibak || text;
      }
    }

    switch (warningLevel) {
      case WarningLevel.Warning:
        toastr.warning(marker + text, title);
        break;
      case WarningLevel.Error:
        toastr.error(marker + text, title);
        break;
      default:
        toastr.error(marker + text, title);
        break;
    }
    return false;
  }
  AjaxSuccess({ title = '', text = '', result }) {
    if (result) {
      title = result.Title || title;
      text = result.Message || text;
    }
    if (result.Aktivitasok) {
      let akivitasAdatok = [];
      result.Aktivitasok.forEach(aktivitas => {
        akivitasAdatok.push(
          `<p><i class="fa-check fas mr-5 text-green"></i><span class="aktivitas-text">${aktivitas.Mit}</span></p>`
        );
      });
      text += `${marker}</br><div class="aktivitasok">${akivitasAdatok.join(
        ''
      )}</div>`;
    }
    toastr.success(text, title);
  }

  async ConfirmModal(title, message, options = {}) {
    let modalClass = 'confirm-modal';

    const messageHtml = hirportalAdmin.$createElement('div', {
      domProps: { innerHTML: '<p class="mb-0">' + message + '</p>' },
    });
    const titleHtml = hirportalAdmin.$createElement('div', {
      domProps: {
        innerHTML:
          '<div class="d-flex align-items-center"><span class="bg-blue-cyan title-border"></span><p class="mb-0">' +
          title +
          '</p></div>',
      },
    });
    return await hirportalAdmin.$bvModal.msgBoxConfirm(messageHtml, {
      title: titleHtml,
      modalClass,
      size: 'lg',
      okVariant: ' k-btn btn-primary',
      okTitle: 'Rendben',
      cancelTitle: 'Mégsem',
      cancelVariant: ' k-btn btn-dark',
      headerClass: 'px-25',
      titleClass: 'h1',
      bodyClass: 'px-25 pb-15',
      footerClass: 'px-15 justify-content-between',
      hideHeaderClose: true,
      ...options,
    });
  }
}

let instance = new NotificationFunctions();
export { instance as NotificationFunctions };
