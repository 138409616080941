import Vue from 'vue';
import './plugins';
import './plugins/app';
import App from './App.vue';

import { router } from './router';

import './components';
import './filters';
import './styles/main.scss';
import { isTokenValid } from './utils/token';
import store from './store';
import { AppFunctions } from './functions/AppFunctions';
import { PLACEHOLDER_IMAGE } from './utils/common';

Vue.prototype.$placeholderImage = PLACEHOLDER_IMAGE;
Vue.config.productionTip = true;
const _eventBus = new Vue();
export const eventBus = _eventBus;

let isLoginScreen = router.currentRoute.name == 'Login';
export let prevRoute = null;
if (isTokenValid()) {
  AppFunctions.GetInitData();
  if (isLoginScreen) {
    router.push({ name: 'Users' });
  }
} else {
  if (!isLoginScreen) {
    prevRoute = location.hash;
    router.push({ name: 'Login' });
  }
}
export const hirportalAdmin = new Vue({
  render: h => h(App),
  router,
  store,
});
hirportalAdmin.$mount('#app');
