const isProd =
  process.env.NODE_ENV === 'production' &&
  !location.host.startsWith('localhost');

const env = process.env.VUE_APP_ENV;

const prodMagazinUrl =
    env == 'eles' ? 'https://streamnext.hu/magazin/'
        : 'https://streamnext.hu/test/magazin/';

const prodUrl =
    env == 'eles' ? 'https://streamnext.hu/'
        : 'https://streamnext.hu/test/';

const settings = {
    isProd: isProd,
    prodBaseUrl: prodMagazinUrl,
    baseUrl: isProd
        ? prodUrl
        : 'https://localhost:5001/',
};

export default settings;
