import Vue from 'vue';
var removeNamespace = function(namespace, types) {
  var result = {};
  for (var mutationType in types) {
    if (!result.hasOwnProperty(mutationType)) result[mutationType] = {};
    for (var exprName in types[mutationType]) {
      var expr = types[mutationType][exprName];
      result[mutationType][exprName] = expr.replace(namespace, '');
    }
  }
  return result;
};
export const IsVueFormValid = function(vm, validator) {
  console.log(vm, validator);
  if ((validator || vm.$v).$invalid) {
    (validator || vm.$v).$touch();
    vm.$nextTick(() => {
      var element = vm.$el.querySelector('form div.error');
      if (element) {
        element.scrollIntoView();
      }
    });
    return false;
  }
  return true;
};
export default removeNamespace;
