import Vue from 'vue';
import VCalendar from 'v-calendar';

Vue.use(VCalendar, {
  componentPrefix: 'vc',
  locales: {
    hu: {
      masks: {
        L: 'YYYY.MM.DD',
      },
    },
  },
});
