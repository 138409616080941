'use strict';

import { AppFunctions } from '../functions/AppFunctions';
import { eventBus } from '../main';
import { router } from '../router';
import settings from '../settings';
import store from '../store';
import { AppStoreTypes } from '../store/modules/app';
import { timeout } from '../utils/common';
import { HttpContext } from '../utils/httpContext';
import { deleteToken, setToken } from '../utils/token';

class UserService {
  async Login({ form }) {
    const url = settings.baseUrl + 'Api/User/Login';
    let data = { ...form, admin: true };
    const result = await HttpContext.post({ url, data });
    let token = result?.Token;
    if (token) {
      setToken(token);
    }
    AppFunctions.GetInitData();
    return result;
  }
  async Logout() {
    deleteToken();
    router.push({ name: 'Login' });
  }

  async GetUsers() {
    const url = settings.baseUrl + 'Api/User/GetUsers';
    const result = await HttpContext.post({ url });
    return result;
  }
  async GetAuthors() {
    const url = settings.baseUrl + 'Api/User/GetUsers?isAuthor=true';
    const result = await HttpContext.post({ url });
    return result;
  }
  async GetUsersByKeresoSzoveg({ keresoszoveg }) {
    let data = {
      keresoszoveg,
    };

    const url = settings.baseUrl + 'Api/User/GetUsersByKeresoSzoveg';
    const result = await HttpContext.post({ url, data });
    return result;
  }
  async GetProfil() {
    const url = settings.baseUrl + 'Api/User/GetProfil';
    const result = await HttpContext.post({ url });
    store.dispatch(AppStoreTypes.user.set, { value: result });
    return result;
  }
  async GetUser({ id }) {
    const url = settings.baseUrl + 'Api/User/GetUser';
    let data = { userId: id };
    const result = await HttpContext.post({ url, data });
    return result;
  }
  async SaveUser(data) {
    const url = settings.baseUrl + 'Api/User/SaveUser';
    const result = await HttpContext.post({ url, data });
    let token = result?.Token;
    if (token) {
      setToken(token);
    }
    return result;
  }
  async ProjektValtas(projektId) {
    const url = settings.baseUrl + 'Api/User/ProjektValtas';
    let data = { projektId };
    const result = await HttpContext.post({ url, data });
    let token = result?.Token;
    if (token) {
      setToken(token);
    }
    store.dispatch(AppStoreTypes.user.set, { value: result });
    eventBus.$emit('projekt-valtas');
    return result;
  }
  async Jelszomodositas(form) {
    const url = settings.baseUrl + 'Api/User/Jelszomodositas';
    let data = { ...form };
    const result = await HttpContext.post({ url, data });
    return result;
  }
}

let instance = new UserService();
export { instance as userService };
