<template>
  <div class="container">
    404
  </div>
</template>

<script>
export default {
  name: 'not-found-page',
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>
ű
