import $ from 'jquery';
import 'datatables.net-bs4';
import 'datatables.net-bs4';
import jszip from 'jszip';
window.JSZip = jszip;
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.css';
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css';
import 'datatables.net-select-bs4/css/select.bootstrap4.css';
import 'jquery-datatables-checkboxes/css/dataTables.checkboxes.css';

import 'datatables.net-buttons/js/dataTables.buttons.js';

import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-select-bs4/js/select.bootstrap4.js';
import 'jquery-datatables-checkboxes/js/dataTables.checkboxes';

import 'datatables.net-buttons/js/buttons.html5.js';

// import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.js';

import 'datatables.net-responsive/js/dataTables.responsive.js';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.js';

import 'datatables.mark.js';
import { formatNumber } from '../utils/common';

var hunlang = {
  sEmptyTable:
    '<span class="dt-empty-text">Nincs rendelkezésre álló adat</span>',
  // https://datatables.net/reference/option/language.info
  sInfo: `
  <span class="dt-info-talalatok-num">
  _MAX_/_TOTAL_</span>
  <span class="dt-info-talalatok">
  </span>`,
  sThousands: ' ',
  sInfoEmpty: '<span class="dt-info-talalatok-num">_MAX_/_TOTAL_</span>',
  sInfoFiltered: '',
  sInfoPostFix: '',
  sInfoThousands: ' ',
  sLengthMenu: '_MENU_',
  sLoadingRecords: 'Betöltés...',
  sProcessing: 'Feldolgozás...',
  sSearch: '',
  searchPlaceholder: 'Szűkítés...',
  sZeroRecords: 'Nincs a keresésnek megfelelő találat',
  oPaginate: {
    sFirst: 'Első',
    sPrevious: '<',
    sNext: '>',
    sLast: 'Utolsó',
  },
  oAria: {
    sSortAscending: ': aktiválja a növekvő rendezéshez',
    sSortDescending: ': aktiválja a csökkenő rendezéshez',
  },
  // select extension innentől
  select: {
    rows: {
      _: 'Kijelölve %d sor',
      0: '',
      1: 'Kijelölve %d sor',
    },
  },
};
$.fn.dataTable.ext.errMode = 'throw';
$.extend(true, $.fn.dataTable.defaults, {
  oLanguage: hunlang,
  infoCallback: function(settings, start, end, max, total, pre) {
    let counter = formatNumber(max);
    if (max != total) {
      counter = `${formatNumber(max)}&nbsp;/&nbsp;${formatNumber(total)}`;
    }
    return `<span class="dt-info-talalatok-num">Találatok: ${counter}</span>
    <span class="dt-info-talalatok"></span>`;
  },
  iDisplayLength: 10,
  aLengthMenu: [
    [5, 10, 25, 50, -1],
    [5, 10, 25, 50, 'Mind'],
  ],
  bDeferRender: true,
  dom: 'lBCfrtip',
  //mark: true,
  //colReorder: true,
  language: {
    buttons: {
      copyTitle: 'Másolás',
      copySuccess: {
        _: 'A vágólapra másolva %d sor',
        0: 'Nincs másolható adat',
        1: 'Másolva 1 sor',
      },
    },
  },

  buttons: [
    {
      text: 'Másolás',
      extend: 'copyHtml5',
      exportOptions: {
        columns: ':visible :not(.noExport)',
      },
    },
    {
      extend: 'excelHtml5',
      text: 'Excel',
    },
    {
      extend: 'print',
      text: 'Nyomtatás',
    },
  ],
});
