<template>
  <div class="wrapper" id="app">
    <header v-if="this.$route.path != '/'">
      <b-container>
        <div class="navbar">
          <b-nav class="w-100 flex-nowrap">
            <b-nav-item :to="{ name: 'Articles' }">Cikkek</b-nav-item>
            <b-nav-item :to="{ name: 'Messages' }"> Üzenetek </b-nav-item>
            <b-nav-item :to="{ name: 'Authors' }"> Szerzők </b-nav-item>
            <b-nav-item :to="{ name: 'Users' }"> Felhasználók </b-nav-item>
            <b-nav-item :to="{ name: 'NewsPreview' }">Magazin</b-nav-item>
            <b-nav-item
              :to="{ name: 'ProjectConfig' }"
              v-if="isProjektAdmin || isGod"
            >
              Devs
            </b-nav-item>
            <b-nav-item
              :to="{ name: 'LayoutConfig' }"
              v-if="isProjektAdmin || isGod"
            >
              Devs2
            </b-nav-item>
            <b-nav-item-dropdown
              :text="aktualisProjekt ? aktualisProjekt.Nev : ''"
              class="ml-auto"
            >
              <b-dropdown-group header="">
                <b-dropdown-item-button
                  v-for="projekt in tovabbiJogosultProjektek"
                  :key="projekt.Id"
                  @click="ProjektValtas(projekt.Id)"
                >
                  {{ projekt.Nev }}
                </b-dropdown-item-button>
              </b-dropdown-group>
              <template v-if="isGod || isAdmin">
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item
                  link-classes="nowrap"
                  @click="UjProjekt"
                  v-if="isGod"
                >
                  Új projekt
                </b-dropdown-item>
                <b-dropdown-item v-if="isAdmin" @click="Sugo">
                  Súgó
                </b-dropdown-item>
              </template>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item link-classes="nowrap" @click="JelszoValtoztatas">
                Jelszó módosítása
              </b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="Logout" link-classes="nowrap">
                Kijelentkezés
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </div>
      </b-container>
    </header>
    <router-view :key="key"></router-view>
    <k-modal-container></k-modal-container>
    <a
      v-if="user && noemiLink"
      :href="noemiLink"
      target="_blank"
      class="noemi-link"
    >
      <img src="@/assets/images/noemi2-logo.png" height="50" />
    </a>

    <k-subscribe-event
      events="projekt-valtas"
      @action="key++"
    ></k-subscribe-event>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AppFunctions } from './functions/AppFunctions';
import { NotificationFunctions } from './functions/NotificationFunctions';
import { eventBus } from './main';
import { userService } from './services/UserService';
import { AppStoreTypes } from './store/modules/app';
import { sortStr } from './utils/sort';
import { ModalFunctions } from './functions/ModalFunctions';
import settings from './settings';
export default {
  name: 'app',
  data() {
    return {
      key: 0,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      noemiLink: AppStoreTypes.noemiLink.get,
      user: AppStoreTypes.user.get,
      jogosultProjektek: AppStoreTypes.jogosultProjektek.getAll,
    }),
    isAdmin() {
      return AppFunctions.IsAdmin(this.user);
    },
    isGod() {
      return AppFunctions.IsGod(this.user);
    },
    isProjektAdmin() {
      return AppFunctions.IsProjektAdmin(this.user);
    },
    aktualisProjekt() {
      return this.user?.Projekt;
    },
    tovabbiJogosultProjektek() {
      let aktualisProjektId = this.aktualisProjekt?.Id;
      let projektek = this.jogosultProjektek.filter(
        (f) => f.Id != aktualisProjektId
      );
      projektek.sort(sortStr('Nev'));
      return projektek;
    },
  },
  methods: {
    Logout() {
      userService.Logout();
    },
    async ProjektValtas(projektId) {
      try {
        await userService.ProjektValtas(projektId);
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
    },
    UjProjekt() {
      ModalFunctions.OpenModal('k-uj-projekt-modal');
    },
    JelszoValtoztatas() {
      ModalFunctions.OpenModal('k-uj-jelszo-modal');
    },
    Sugo() {
      window.open(settings.prodBaseUrl + '#/megosztott-hir/igVgwOZ9', '_blank');
    },
  },
  watch: {
    aktualisProjekt: {
      async handler(p) {
        await this.$nextTick();
        if (p?.Nev) {
          document.title = `Streamnext - ${p.Nev}`;
        } else {
          document.title = 'Streamnext';
        }
      },
      deep: true,
      immediate: true,
    },
    key: {
      async handler() {
        AppFunctions.GetInitData();
      },
    },
  },
};
</script>
