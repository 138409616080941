import { timeout } from '../../utils/common';
import removeNamespace from '../../utils/vueUtils';

export const ModalStoreTypes = {
  getters: {
    isModalOpen: 'modal/isModalOpen',
    getModals: 'modal/getModals',
    getModalByName: 'modal/getModalByName',
  },
  actions: {
    CloseModal: 'modal/CloseModal',
  },
  mutations: {
    OPEN_MODAL: 'modal/OPEN_MODAL',
    CLOSE_MODAL: 'modal/CLOSE_MODAL',
    REMOVE_MODAL: 'modal/REMOVE_MODAL',
    CLOSE_ALL_MODAL: 'modal/CLOSE_ALL_MODAL',
    SET_MODAL_LOADING: 'modal/SET_MODAL_LOADING',
  },
};

const _types = removeNamespace('modal/', ModalStoreTypes);

const state = {
  idxCt: 0,
  modals: [],
  isModalOpen: false,
};

const getters = {
  [_types.getters.isModalOpen]: (state, getters, rootState) => {
    return state.modals.find(f => f.isOpened == true) != null;
  },
  [_types.getters.getModals]: (state, getters, rootState) => {
    return state.modals;
  },
  [_types.getters.getModalByName]: (state, getters, rootState) => (
    name,
    idx = -1
  ) => {
    let modal = state.modals.find(f => f.name == name && f.idx == idx);
    let defaultModal = {
      name: name,
      data: null,
      isOpened: false,
      isLoading: false,
      idx: -1,
    };
    return modal || defaultModal;
  },
};

const actions = {
  [_types.actions.CloseModal]: async ({ commit }, value) => {
    commit(_types.mutations.CLOSE_MODAL, value);
    await timeout(1000);
    commit(_types.mutations.REMOVE_MODAL, value);
  },
};

const mutations = {
  [_types.mutations.OPEN_MODAL](state, value) {
    let modals = state.modals;

    state.modals.push({
      name: value.name,
      data: value.data,
      isOpened: true,
      isLoading: false,
      idx: state.idxCt,
    });

    state.modals = modals;
    state.idxCt++;
  },
  [_types.mutations.SET_MODAL_LOADING](state, value) {
    let modal = state.modals.find(
      f => f.name == value.name && f.idx == value.idx
    );
    if (modal) {
      modal.isLoading = value.isLoading;
    }
  },
  [_types.mutations.CLOSE_MODAL](state, value) {
    let modal = state.modals.find(
      f => f.name == value.name && f.idx == value.idx
    );
    if (modal) {
      modal.isOpened = false;
      modal.isLoading = false;
    }
  },
  [_types.mutations.REMOVE_MODAL](state, value) {
    let modals = state.modals.filter(
      f => f.name + '_' + f.idx != value.name + '_' + value.idx
    );

    state.modals = modals;
  },

  [_types.mutations.CLOSE_ALL_MODAL](state) {
    state.modals = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
