import { Jogosultsag } from '../enums/Cimkek/jogosultsag';
import { appService } from '../services/AppService';
import { hirService } from '../services/HirService';
import { userService } from '../services/UserService';

class AppFunctions {
  async GetInitData() {
    await appService.GetAppData();
    await appService.GetSzervezetek();
    await hirService.GetKategoriak();
    await hirService.GetHirStatuszok();
  }
  IsProjektAdmin(user) {
    return user?.ProjektAdmin ?? false;
  }
  IsGod(user) {
    return user?.IsGod ?? false;
  }
  IsSzerkeszto(user) {
    return user?.Jogosultsag?.Id == Jogosultsag.Szerkeszto;
  }
  IsAdmin(user) {
    return user?.Jogosultsag?.Id == Jogosultsag.Szerkeszto
      || user?.Jogosultsag?.Id == Jogosultsag.Szerzo
      || user?.ProjektAdmin
      || user?.IsGod;
  }
}

let instance = new AppFunctions();
export { instance as AppFunctions };
