import moment from 'moment';

var tokenName = 'StreamnextAdmin';

export function setToken(token) {
  localStorage.setItem(tokenName, token);
}

export function getToken() {
  return localStorage.getItem(tokenName);
}

export function deleteToken() {
  localStorage.removeItem(tokenName);
}

export function isTokenValid() {
  var token = getToken();
  if (!token) return false;
  var tokenData = parseJwt(token);
  return moment().unix() < tokenData.exp;
}

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
