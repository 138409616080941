import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from './views/NotFound.vue';
import NoPermission from './views/NoPermission.vue';
import Login from './views/Login.vue';
import $ from 'jquery';
import { isTokenValid } from './utils/token';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/users',
    name: 'Users',
    component: () =>
      import(/* webpackChunkName: "page-users" */ './views/Users.vue'),
  },
  {
    path: '/authors',
    name: 'Authors',
    component: () =>
      import(/* webpackChunkName: "page-users" */ './views/Authors.vue'),
  },
  {
    path: '/messaging',
    name: 'Messaging',
    component: () =>
      import(/* webpackChunkName: "page-users" */ './views/Messaging.vue'),
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () =>
      import(/* webpackChunkName: "page-messages" */ './views/Messages.vue'),
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () =>
      import(/* webpackChunkName: "page-articles" */ './views/Articles.vue'),
  },
  {
    path: '/article/:id?',
    name: 'Article',
    component: () =>
      import(/* webpackChunkName: "page-article" */ './views/Article.vue'),
  },
  {
    path: '/news-preview/',
    name: 'NewsPreview',
    component: () =>
      import(/* webpackChunkName: "news-preview" */ './views/NewsPreview.vue'),
  },
  {
    path: '/project-config/',
    name: 'ProjectConfig',
    component: () =>
      import(
        /* webpackChunkName: "project-config" */ './views/ProjectConfig.vue'
      ),
  },
  {
    path: '/layout-config/',
    name: 'LayoutConfig',
    component: () =>
      import(
        /* webpackChunkName: "layout-config" */ './views/LayoutConfig.vue'
      ),
  },
  {
    path: '/no-permission',
    name: 'NoPermission',
    component: NoPermission,
    meta: { auth: false },
  },
  { path: '*', name: 'NotFound', component: NotFound },
];

export const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
    $('.tooltip').tooltip('hide');
    if (isTokenValid() && to.path == '/') {
        next({ path: '/articles' });
        return;
    }
    next();
});
