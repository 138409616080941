import settings from '../settings';
import store from '../store';
import { ModalStoreTypes } from '../store/modules/modal';
import { timeout } from '../utils/common';

const MODAL_INFO_KEY = 'F2020_ModalInfo';

export const ModalDataType = Object.freeze({
  /** 1 */
  SzerverOldaliSzuro: 1,
});

class ModalFunctions {
  async OpenModal(name, data = null) {
    await timeout(30);
    store.commit(ModalStoreTypes.mutations.OPEN_MODAL, {
      name: name,
      data,
    });
  }
  async CloseModal(name, idx) {
    store.dispatch(ModalStoreTypes.actions.CloseModal, {
      name: name,
      idx,
    });
  }
  async SetLoading(name, idx, isLoading = true) {
    store.commit(ModalStoreTypes.mutations.SET_MODAL_LOADING, {
      name: name,
      idx,
      isLoading,
    });
  }
  async CloseAll() {
    store.commit(ModalStoreTypes.mutations.CLOSE_ALL_MODAL);
  }

  IsModalOpen() {
    return store.getters[ModalStoreTypes.getters.isModalOpen];
  }
}

let instance = new ModalFunctions();
export { instance as ModalFunctions };

global.BvAdmin.functions.modalFunctions = instance;
