var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vc-date-picker',_vm._b({attrs:{"value":_vm.localValue,"is24hr":"","locale":"hu"},on:{"input":_vm.OnInput},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var updateValue = ref.updateValue;
var togglePopover = ref.togglePopover;
return [_c('b-form-input',{staticClass:"custom-date-picker",attrs:{"value":inputValue,"autocomplete":"off","required":_vm.required},on:{"input":function($event){return updateValue($event, {
          formatInput: false,
          hidePopover: false,
          debounce: 550,
        })},"change":function($event){return updateValue($event, {
          formatInput: true,
          hidePopover: false,
        })},"click":function($event){return togglePopover({
          placement: 'bottom-start',
        })}}})]}}])},'vc-date-picker',Object.assign({}, _vm.$attrs),false))}
var staticRenderFns = []

export { render, staticRenderFns }