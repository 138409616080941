import moment from 'moment';

class HirFunctions {
  async ModifyFormDataFromServer(article) {
    article.MegjelenesDatuma = moment(article.MegjelenesDatuma).format(
      'YYYY-MM-DDTHH:mm'
    );
  }
}

let instance = new HirFunctions();
export { instance as HirFunctions };
